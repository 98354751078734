@import 'utils';

:root {
    --clr-1: #151e23;
    --clr-2: #5ea6d5;
    --clr-3: #f7ce4c;
    --clr-4: #3a454e;
}


* { box-sizing: border-box; }

body {
    background-color: var(--clr-1); color: var(--clr-4); margin: 0; padding: 0; font-family: arial;
}



#character {
    display: flex; justify-content: space-between; padding-right: 30px; align-items: center;
    .char-display svg path {
        fill: none; stroke-width: 2; stroke: white;
        animation: draw 0.5s infinite linear; animation-fill-mode: forwards;
    }

    .prev-next {
        display: flex; align-items: center;

        & > div:not(.variants) {
            background-color: var(--clr-2); color: #fff; height: 60px; width: 50px; display: flex; justify-content: center; align-items: center; font-size: 40px; font-weight: 800; cursor: pointer; font-family: Arial, Helvetica, sans-serif; border-radius: 10px;
            svg { padding: 15px; width: 100%; }
            @include mobile {
                font-size: 20px; height: 30px; width: 30px; border-radius: 5px; margin: 0 5px;
                svg { padding: 0; width: 20px; height: 20px; }
            }

        }

        .variants {
            color: #fff; column-gap: 0; display: flex; justify-content: center; align-items: center; margin: 0 15px;
            @include tablet { margin: 0 5px; }
            
            @include mobile { display: none; }
            div {
                border: solid 1px transparent; font-size: 60px; height: 60px; display: flex; justify-content: center; align-items: center;
                @include tablet { font-size: 30px; height: 30px; }
                &:nth-child(4) { font-family: "BIZ UDPMincho"; }
                &:nth-child(3) { font-family: "Noto Serif JP"; }
                &:nth-child(2) { font-family: "MHGKyokashotaiTHK"; }
                &:nth-child(1) { font-family: "Noto Sans JP"; margin-left: 4px; font-weight: 100; }
            }
        }
    }
    .sound {
        height: 60px; background: var(--clr-4); border-radius: 10px; cursor: pointer; display: flex; align-items: center; padding: 0; 
        svg { width: 60px; height: 100%; background: var(--clr-2); padding: 10px; border-radius: inherit; } 
        path { fill: white; } 
        .romanization { padding: 0 20px; color: #fff; font-size: 30px; font-family: Arial; }
        @include mobile {
            height: 30px; border-radius: 5px;
            svg { width: 30px; padding: 5px; }
            .romanization { font-size: 20px; padding: 0 10px; }
        }
    }

    .open-settings {
        width: 60px; height: 60px; background: var(--clr-4); border-radius: 10px; cursor: pointer; display: flex; justify-content: center; align-items: center;
        svg { width: 100%; padding: 15px; }
        @include mobile {
            height: 30px; width: 30px; border-radius: 5px;
            svg { padding: 7px; }
        }
    }
    
}

@keyframes draw {
	75%, 100% { stroke-dashoffset: 0; }
}

#charlist-container {
    button { position: fixed; background-color: var(--clr-2); border: none; color: #fff; bottom: 0; z-index: 2; right: 0; height: 40px; width: 100px; border-radius: 10px 0 0 0; }
}
ul#char-list {
    background: var(--clr-1); display: flex; flex-wrap: wrap; align-items: flex-start; align-content: flex-start; justify-content: space-evenly; row-gap: 5px; height: 100vh; left: 0; list-style: none; margin: 0; overflow: auto; padding: 0; position: fixed; top: 0; width: 100%; z-index: 1; padding-top: 5px; padding-bottom: 43px;
    li {
        align-items: center; border: 1px solid var(--clr-4); border-radius: 5px; cursor: pointer; display: flex; flex-direction: column; flex: 0 0 calc(20% - 5px); height: 60px; justify-content: center;
        .jp { color: white; font-family: Kokoro; }
        .eng { font-family: 'Roboto'; }

        &.sound {
            justify-content: flex-start;
            .jp { font-size: 36px; }
        }
    }
}

main {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

header {
    height: 20vh;
    .char-display {
        cursor: pointer;
        svg {
            height: 20vh;
            width: 20vh;
        }
    }
}

#canvas-container {
    background-color: rgba(0,0,0,0.1);
    flex: 1; position: relative;
    margin: 30px; margin-top: 0;
    //background-color: var(--clr-1);
    .canvas-container {
        width: 100%!important; height: 100%!important; overflow: hidden;
        canvas { width: 100% !important; height: auto !important; }
    }

    .lines {
        position: absolute; width: 100%; left: 0; top: 0; height: 100%; pointer-events: none; display: flex;  justify-content: space-evenly;
        div {
            background-color: var(--clr-4) ; display: inline-block;
            &:nth-child(odd) { opacity: 0.2; }
        }
        &.rows {
            flex-direction: column;
            div { width: 100%; height: 1px; }
            div:nth-child(odd) { background: linear-gradient(to right, transparent 50%, var(--clr-4) 50%); background-size: 10px 10px; background-repeat: repeat; }
        }
        &.cols {
            div { height: 100%; width: 1px; }
            div:nth-child(odd) { background: linear-gradient(to bottom, transparent 50%, var(--clr-4) 50%); background-size: 10px 10px; background-repeat: repeat; }
        }
    }
}

#settings {
    position: fixed; right: 0; top: 0; bottom: 0; width: 20vw; padding: 30px; background-color: rgba(0,0,0,0.35); z-index: 2; backdrop-filter: blur(5px); transform: translateX(100%); transition: 0.3s; box-shadow: -5px 0 10px 0px rgba(0,0,0,0.3); min-width: 350px;
    &.open { transform: translateX(0); }

    .heading {
        display: flex; justify-content: flex-end; align-items: center; margin-bottom: 20px;
    }
    .close-icon {
        width: 30px; height: 30px; cursor: pointer;
        svg { width: 100%; height: 100%; }
    }

    .setting {
        margin-bottom: 20px;
        .name { color: #fff; font-size: 18px; margin-bottom: 5px; }
    }
}